@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: "Helvetica", Helvetica, Arial, sans-serif;
  color: #221C07;
  background-color: #FAFAFA;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* NAVBAR STYLING */

.navbar-menu {

  max-width: 0;
  opacity: 0;
  transition: max-width 0.3s ease, opacity 0.3s ease;
  transform: translateX(100%);

}

.navbar-menu.nav_active {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  box-shadow: 2px 1px 12px 1px rgba(0, 0, 0, 0.1);
  width: 70%;
  margin-right: -10px;
  padding-right: 20px;
  padding-left: 10px;
  padding-top: 30px;
  border-radius: 4px;
  top: 0;
  color: #221C07;
  max-width: 350px;
  background-color: #fafafa;
  opacity: 1;
  animation: slideIn 0.3s ease forwards, fadeIn 0.3s ease forwards;
}

.navbar-menu.nav_closing {
  max-width: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-width 0.3s ease, opacity 0.3s ease;
  animation: slideOut 0.3s ease forwards, fadeOut 0.3s ease forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.svg_icon {
  width: 100%;
  display: flex;
  height: 100%;
  fill: #221C07;
}

.close_button {
  height: 1.45em;
  width: 1.2em;
  cursor: pointer;
}

/* Homepage animation */

.color-background-1 {
  z-index: -9999 !important;
  background:
    linear-gradient(rgb(79, 64, 47), transparent),
    linear-gradient(to top left, rgb(75, 66, 52), transparent),
    linear-gradient(to top right, rgb(88, 81, 70), transparent), url(https://grainy-gradients.vercel.app/noise.svg) !important;
  background-blend-mode: multiply;
  margin-right: -6%;
  filter: contrast(120%) brightness(100%);
}

.color-background-2 {
  margin-bottom: -10%;
  margin-right: -18%;
  z-index: -9999 !important;

  background:
    linear-gradient(rgb(241, 180, 23), transparent),
    linear-gradient(to top left, rgb(241, 180, 23), transparent),
    linear-gradient(to top right, rgb(239, 203, 119), transparent), url(https://grainy-gradients.vercel.app/noise.svg) !important;
  background-blend-mode: multiply;
  filter: contrast(120%) brightness(100%);
}


.shadow {
  box-shadow: 0 0 10px 2px rgba(43, 27, 10, 0.059);
}

.menu {
  z-index: 9999 !important;

}

.footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fafafa;
}

.html5-video-player:not(.ytp-transparent),
.html5-video-player.unstarted-mode,
.html5-video-player.ad-showing,
.html5-video-player.ended-mode {

  background-color: #fafafa !important;
}

input[type="email"]:focus,
input[type="text"]:focus {
  outline: none;
  border: 2px solid #221C07;
  background-color: #fafafa !important;
}

textarea:focus {
  outline: none;
  border: 2px solid #221C07;
  background-color: #fafafa !important;
}

input[type="email"]::selection,
input[type="text"]::selection {
  background-color: #fafafa !important;
  color: #221C07;
}

input[type="email"]:-webkit-autofill,
input[type="text"]:-webkit-autofill {
  background: #fafafa !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fafafa inset !important;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: none;
}

.overlay.open {
  display: flex;
}

.modal.open {
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 10px 2px rgba(43, 27, 10, 0.19);
}

.modal.closed {
  display: none;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  display: hidden;
  transform: translate(-50%, -50%);
  background-color: #fafafa;
  padding: 0.75em;
  padding-top: 0.05em;
  padding-bottom: 1.125em;
  z-index: 1001;
}


.close {
  position: absolute;
  top: 1px;
  right: 5px;
  cursor: pointer;
  font-size: 1.5em;
  color: #2B2308;
}

.list-disc::before {
  content: "";
  display: inline-block;
  margin-bottom: 0.3em;
  width: 16px;
  height: 3px;
  background-color: #2B2308;
  border-radius: 0;
  margin-right: 8px;
}

ul.custom-list {
  list-style-type: none;
  padding: 0;
}

/* Define a custom bullet style */
ul.custom-list li::before {
  content: "";
  display: inline-block;
  margin-bottom: 0.075;
  width: 16px;
  height: 2px;
  background-color: #2B2308;
  border-radius: 0;
  margin-right: 8px;
}

.background-video-container {
  position: fixed;
  width: 1200vw;
  height: 100vh;
  overflow: hidden;
  z-index: -9999 ;
}

/* Add media query for smaller screens (e.g., mobile) */
@media (max-width: 768px) {
  .background-video-container {
    height: 300vh; /* Adjust the height as needed for mobile */
    overflow: hidden;
    background-color: #221C07;
    background-blend-mode: exclusion;
   mix-blend-mode:darken;

  }
}

.scroll-animation {
  position: relative; 
}
.fade-in-hero-1 {
  transform: translateX(30vw) translateY(-20vh);
  animation: TranslateHero1 8s ease-in-out forwards;
}

.fade-out-hero-1 {
  transform: translateX(30vw) translateY(-20vh);

  animation: TranslateHeroOut1 8s ease-in-out backwards;
}

.fade-in-hero-2 {
  transform: translateX(2vw) translateY(10vh);
  animation: TranslateHero2 8s ease-in-out forwards;
}

.fade-out-hero-2 {
  transform: translateX(2vw) translateY(10vh);

  animation: TranslateHero2Out 8s ease-in-out backwards;
}

@keyframes TranslateHero1 {
  from {
    transform: translateX(30vw) translateY(-20vh);
  }
  50% {
    transform: translateX(20vw) translateY(-20vh);
  }
  to {
    transform: translateX(30vw) translateY(-30vh);
  }
}

@keyframes TranslateHeroOut1 {
  from {
    transform: translateX(30vw) translateY(-30vh);
  }
  50% {
    transform: translateX(20vw) translateY(-20vh);
  }
  to {
    transform: translateX(30vw) translateY(-20vh);
  }
}

@keyframes TranslateHero2 {
  from {
    transform: translateX(2vw) translateY(10vh);
  }
  50% {
    transform: translateX(-3vw) translateY(15vh);
  }
  to {
  transform: translateX(10vw) translateY(20vh);
  }
}

@keyframes TranslateHero2Out {
  from {
    transform: translateX(10vw) translateY(20vh);
  }
  50% {
    transform: translateX(-3vw) translateY(15vh);
  }
  to {
    transform: translateX(2vw) translateY(10vh);
  }
}


.fade-in-hero-1-mobile {
  transform: translateX(90vw) translateY(-10vh);
  animation: TranslateHero1Mobile 8s ease-in-out forwards;
}

.fade-out-hero-1-mobile {
  transform: translateX(90vw) translateY(-10vh);

  animation: TranslateHeroOut1Mobile 8s ease-in-out backwards;
}

.fade-in-hero-2-mobile {
  transform: translateX(10vw) translateY(70vh);
  animation: TranslateHero2Mobile 8s ease-in-out forwards;
}

.fade-out-hero-2-mobile {
  transform: translateX(10vw) translateY(70vh);

  animation: TranslateHero2OutMobile 8s ease-in-out backwards;
}

@keyframes TranslateHero1Mobile {
  from {
    transform: translateX(90vw) translateY(-10vh);
  }
  50% {
    transform: translateX(105vw) translateY(-5vh);
  }
  to {
    transform: translateX(120vw) translateY(-20vh);
  }
}

@keyframes TranslateHeroOut1Mobile {
  from {
    transform: translateX(120vw) translateY(-20vh);
  }
  50% {
    transform: translateX(85vw) translateY(-15vh);
  }
  to {
    transform: translateX(90vw) translateY(-10vh); /* Return to initial position */
  }
}

@keyframes TranslateHero2Mobile {
  from {
    transform: translateX(10vw) translateY(70vh);
  }
  50% {
    transform: translateX(-4vw) translateY(60vh);
  }
  to {
    transform: translateX(5vw) translateY(70vh);
  }
}

@keyframes TranslateHero2OutMobile {
  from {
    transform: translateX(5vw) translateY(70vh);
  }
  50% {
    transform: translateX(-4vw) translateY(80vh);
  }
  to {
    transform: translateX(10vw) translateY(70vh); /* Return to initial position */
  }
}

.down-arrow-icon {
  width: 100%;
  height: auto;
}